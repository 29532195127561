import React from 'react';
import PropTypes from 'prop-types';

import styles from './Logo.module.css';
import logo from '../../../assets/images/ATK_Logo.svg';

const Logo = props => {
  return (
    <img
      src={logo}
      alt="Autokino Wien"
      width={props.width}
      height={props.height}
      className={styles.LogoBaseline}
    />
  );
};

Logo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Logo;

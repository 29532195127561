import React from 'react';

import styles from './Footer.module.css';
import Column from './Column/Column';
import { getClassName } from '../UI/ResponsiveWrapper/ResponsiveWrapper';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-scroll';
import LinkItemStyles from '../UI/LinkItem/LinkItem.module.css';

/**
 * TODO
 * Implement select elements
 * Render the links from the database
 */

const Footer = () => {
  var columns = [];
  if (window.location.pathname.includes('flohmarkt')) {

    columns = [
      {
        title: 'ÜBER UNS',
        links: [
          {
            title: 'Kino + Kinoprogramm',
            desc: 'Autokino Wien, Kinoprogramm',
            url: ROUTES.LANDING,
          },
          {
            title: 'Kino Gutschein kaufen',
            desc: 'Autokino Wien, Kino Gutschein kaufen',
            url: ROUTES.GIFTCARD,
          },
          {
            title: 'Flohmarkt',
            desc: 'Flohmarkt Wien Autokino',
            url: ROUTES.EVENT,
          },
          {
            title: 'Kinderflohmarkt',
            desc: 'Kinderflohmarkt im Autokino Wien',
            url: ROUTES.KINDERFLOHMARKT,
          },
          {
            title: 'Eventlocation',
            desc: 'Dein Eventlocation im Autokino',
            url: ROUTES.EVENT_LOCATION,
          },
          {
            title: 'Impressum',
            desc: 'Impressum',
            url: '/privacy',
          },
        ],
      },
      {
        title: 'FLOHMARKT BEREICH',
        links: [
          {
            title: 'Standgebühr',
            desc: 'Flohmarkt Standgebühr, große Plätze + faire Preise',
            url: ROUTES.FLOHMARKT_STANDGEBUEHREN,
          },
          {
            title: 'Flohmarkttipps Anfänger',
            desc: 'Deine Tipps für dich als Flohmarktanfänger',
            url: ROUTES.ANFAENGER_TIPPS_FLOHMARKT,
          },
          {
            title: 'Vorteile Aussteller im Autokino',
            desc:
              'Diese Vorteile erwarten dich als Aussteller am Flohmarkt im Autokino',
            url: ROUTES.VORTEILE_AUSSTELLER,
          },
          {
            title: 'Flohmarkt Standplatz buchen',
            desc: 'Bequem online deinen Flohmarktstand buchen',
            url: ROUTES.JETZT_STANDPLATZ_BUCHEN,
          },
        ],
      },
    ];
  } else {
    columns = [
      {
        title: 'ÜBER UNS',
        links: [
          {
            title: 'Kino + Kinoprogramm',
            desc: 'Autokino Wien, Kinoprogramm',
            url: ROUTES.LANDING,
          },
          {
            title: 'Kino Gutschein kaufen',
            desc: 'Autokino Wien, Kino Gutschein kaufen',
            url: ROUTES.GIFTCARD,
          },
          {
            title: 'Flohmarkt',
            desc: 'Flohmarkt Wien Autokino',
            url: ROUTES.EVENT,
          },
          {
            title: 'Kinderflohmarkt',
            desc: 'Kinderflohmarkt im Autokino Wien',
            url: ROUTES.KINDERFLOHMARKT,
          },
          {
            title: 'Eventlocation',
            desc: 'Dein Eventlocation im Autokino',
            url: ROUTES.EVENT_LOCATION,
          },
          {
            title: 'Impressum',
            desc: 'Impressum',
            url: '/privacy',
          },
        ],
      },
      {
        title: 'KINO BEREICH',
        links: [
          {
            title: 'Familienausflug',
            desc: 'Familienausflug',
            url: ROUTES.FAMILIENAUSFLUG_KINO,
          },
          {
            title: 'Mädelsabend',
            desc: 'Mädelsabend',
            url: ROUTES.MAEDELSABEND_KINO,
          },
          {
            title: 'Kinderkino',
            desc: 'Kinderkino',
            url: ROUTES.KINDER_KINO,
          },
          {
            title: 'Sommerkino',
            desc: 'Sommerkino',
            url: ROUTES.OPENAIR_AUTOKINO,
          },
          {
            title: 'Dating Location',
            desc: 'Dating Location',
            url: ROUTES.DATE_IDEEN_WIEN,
          },
          {
            title: 'Demnächst im Kino',
            desc: 'Demnächst im Kino',
            url: ROUTES.UPCOMING_MOVIES,
          },
          {
            title: 'Heute im Kino',
            desc: 'Heute im Kino',
            url: ROUTES.HEUTE_IM_KINO,
          },
          {
            title: 'Top Kinofilme 2025',
            desc: 'Top Kinofilme 2025',
            url: ROUTES.TOPKINO_2025,
          },
          {
            title: 'Fantastic Four',
            desc: 'Fantastic Four',
            url: ROUTES.FANTASTIC_FOUR,
          },
          {
            title: 'Die Schlümpfe',
            desc: 'Die Schlümpfe',
            url: ROUTES.DIE_SCHLUEMPFE,
          },
        ],
      }
    ];
  }

  const cols = columns.map((e, i) => {
    return <Column title={e.title} links={e.links} key={i} />;
  });
  return (
    <div className={[styles.Footer, getClassName()].join(' ')}>{cols}</div>
  );
};

export default Footer;

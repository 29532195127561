import React from 'react';
import PropTypes from 'prop-types';

import styles from './FlohmarktLogo.module.css';
import logo from '../../../assets/images/FM_Logo.svg';

const FlohmarktLogo = props => {
  return (
    <img
      src={logo}
      alt="Flohmarkt Wien im Autokino"
      className={styles.LogoBaseline}
      width={props.width}
      height={props.height}
    />
  );
};

FlohmarktLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default FlohmarktLogo;

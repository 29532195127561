import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { Link } from 'react-router-dom';

import ShowThumb from '../../components/ShowThumb/ShowThumb';
import EventThumb from '../../components/EventThumb/EventThumb';
import styles from './ShowsList.module.css';
import stylesShowThumb from '../../components/ShowThumb/ShowThumb.module.css';
import stylesActionBox from '../../components/ShowThumb/ActionsBox/ActionsBox.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';
import * as actions from '../../store/actions';
import * as ROUTES from '../../constants/routes';
import { Col, Container, Image, Row } from 'react-bootstrap';
import coverAutokinoLocation from '../../assets/images/IMG_0028.JPG';
import coverAutokino2022Opening from '../../assets/images/start-2022.gif';
import coverFahrradTicket from '../../assets/images/AK_Fahrrad_Webseite.jpg';
import imageCharity2023 from '../../assets/images/autokino/bild_charity_web.jpg';
import ResponsiveWrapper from '../../components/UI/ResponsiveWrapper/ResponsiveWrapper';
import NewsletterSignup from '../../components/NewsletterSignup/NewsletterSignup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/button';
import { scroller } from 'react-scroll';
import FilmprogrammSignup from '../../components/FilmprogrammSignup/FilmprogrammSignup';
import Kinostyles from '../../components/Util/AutokinoStylesComponent.module.css';
import empty_show_animation from '../../assets/images/autokino/kinoprogramm/desktop/Empty_Show_Animation.gif';

const AnimatedContainer = posed.div({
  enter: {
    opacity: 1,
    staggerChildren: 50,
    beforeChildren: true,
  },
  leave: {
    opacity: 0,
    staggerChildren: 20,
    staggerDirection: -1,
  },
  initialPose: 'leave',
});

const AnimatedChild = posed.div({
  enter: {
    x: '0%',
    y: 0,
    opacity: 1,
  },
  leave: {
    x: '-100%',
    y: 80,
    opacity: 0,
  },
});

const ShowsList = props => {
  const {
    saveMovieReminderSubscription = props.saveMovieReminderSubscription,
  } = props;
  var selectedMovies = [];
  var emailadresse = '';
  const [showNewsletterSignup, setShow] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = e => {
    console.log('handleChange called for movie: ' + e.target.value);
    //setField("checkbox", e.target.value);
    setCheckboxField('checkboxValues', e.target.value, e.target.checked);
    if (e.target.checked) {
      selectedMovies.push(e.target.value);
    } else {
      selectedMovies.pop(e.target.value);
    }
  };
  const handleShow = (value, preselectedMovieId) => {
    setField('email', '');
    setField('checkboxValues', [preselectedMovieId]);
    setErrors([]);
    setShow(value);
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };
  const setCheckboxField = (field, value, checked) => {
    var newArray = [];
    const { email, checkboxValues } = form;
    console.log(
      'setCheckboxField email: ' + email + ' checkBoxValues ' + checkboxValues
    );
    if (checkboxValues != null) {
      for (let index = 0; index < checkboxValues.length; index++) {
        const element = checkboxValues[index];
        newArray.push(element);
      }
    }
    if (checked) {
      newArray.push(value);
    } else {
      newArray.pop(value);
    }
    setForm({
      ...form,
      [field]: newArray,
    });
  };

  const renderMovieListCheckboxes = () => {
    console.log(
      '!!!! Render renderMovieListCheckboxes called  = ' + props.shows.length
    );

    if (props.shows != null && props.shows.length > 0) {
      return props.shows.map((p, index) => {
        return p.showData.map((data, index) => {
          if (data.movie != null && data.date.endsWith('2099')) {
            console.log('in movies array movie: ' + data.movie.movieName);
            var checked = null;
            if (form.checkboxValues != null) {
              console.log(
                'checked checkboxes ' +
                  form.checkboxValues +
                  ' ' +
                  !!form.checkboxValues.includes(data.movieId)
              );
              checked = form.checkboxValues.includes(data.movie.movieId);
            }
            const child = (
              <Form.Group as={Row}>
                <Col sm="2">
                  {checked ? (
                    <Form.Control
                      type="checkbox"
                      onChange={e => handleChange(e)}
                      value={data.movie.movieId}
                      placeholder="Film"
                      isInvalid={!!errors.checkbox}
                      checked={checked}
                    />
                  ) : (
                    <Form.Control
                      type="checkbox"
                      onChange={e => handleChange(e)}
                      value={data.movie.movieId}
                      placeholder="Film"
                      isInvalid={!!errors.checkbox}
                    />
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.checkbox}
                  </Form.Control.Feedback>
                </Col>
                <Form.Label sm="8">{data.movie.movieName}</Form.Label>
              </Form.Group>
            );
            return <div>{child}</div>;
          } else {
            return '';
          }
        });
      });
    }
  };
  const findFormErrors = () => {
    const { email, checkboxValues } = form;
    const newErrors = {};

    //email
    const expression = /\S+@\S+/;
    if (email) {
      let validEmail = expression.test(email.toLowerCase());

      if (!validEmail) {
        console.log('emailadresse: ' + email + ' is not valid!!');
        newErrors.email = 'Bitte geben Sie eine gültige Emailadresse ein!';
      } else {
        emailadresse = email;
      }
    } else {
      newErrors.email = 'Bitte geben Sie eine gültige Emailadresse ein!';
    }
    console.log('selected values: ' + checkboxValues);
    if (!checkboxValues || checkboxValues.length < 1) {
      newErrors.checkbox =
        'Bitte wählen Sie mind. einen Film für den Sie sich interessieren!';
    } else {
      selectedMovies = checkboxValues;
    }

    return newErrors;
  };
  const submitMovieSubscription = e => {
    console.log(
      'submitMovieSubscription called selected movies: ' + selectedMovies
    );
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    } else {
      var data = { email: emailadresse, movieIds: selectedMovies };
      console.log('sending data: ' + data);
      setShow(false);
      saveMovieReminderSubscription(data);
    }
  };

  function scrollTo(anchor) {
    scroller.scrollTo(anchor, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  }

  const addToCartHandler = (e, item) => {
    e.preventDefault();

    const { movieId, title, imagePath, price, movie, thumbImage } = item;

    props.onAddToCart({
      amount: 1,
      movieId,
      product: {
        id: movieId,
        price,
        title: imagePath ?? thumbImage,
        thumbnail: imagePath ?? thumbImage,
      },
      updated: false,
    });
  };

  const toggleWishlistHandler = item => {
    const { id, title, price, thumbnails } = item;

    if (props.wishlist.hasOwnProperty(id)) {
      // Delete if it is in wishlist
      props.onRemoveFromWishlist(id);
    } else {
      // Add if it is not in wishlist
      props.onAddToWishlist({
        id,
        title,
        price,
        thumbnail: thumbnails[0],
      });
    }
  };

  const renderNewsletterSignup = () => {
    if (props.shows != null && props.shows.length > 0) {
      if (props.shows[0] != null && props.shows[0].showData[0].movie !== null) {
        return (
          <Container className={'mb-4'}>
            <div className={stylesShowThumb.CTAHeadline}>
              Du hast keinen passenden Kino Film für dich gefunden?
            </div>
            <div className={styles.NewsletterDesc}>
              Dann such doch in der Rubrik Bald im Kino.
            </div>
            <div className={'mt-2'}>
              <Link
                to={ROUTES.UPCOMING_MOVIES}
                title="Bald im Kino"
                className={'btn btn-first'}
              >
                Bald im Kino
              </Link>
            </div>
            {/* <NewsletterSignup /> */}
          </Container>
        );
      }
    }
    return '';
  };

  const renderShows = () => {
    // console.log('!!!! Render Shows  = ' +props.shows[0].movieId +',name = ' +props.shows[0].movieName);
    if (props.shows != null && props.shows.length > 0) {
      return props.shows.map((p, index) => {
        const dateString = p.dateString;
        console.log(
          'inMAPP dateString = ' +
            dateString +
            ' count for date: ' +
            p.showData.length
        );
        const animatedChildFunc = p.showData.map((data, index) => (
          <Col xs={12} lg={4}>
            <AnimatedChild
              className={styles.Column + ' ' + styles.ShowCard + ' p-2'}
              key={data.showId}
            >
              {data.movie !== null ? (
                <ShowThumb
                  item={data}
                  key={data.showId}
                  onAddToCart={e => addToCartHandler(e, data)}
                  addingToCart={props.isAddingToCart[data.showId]}
                  inCart={props.cart.hasOwnProperty(data.showId)}
                  toggleWishlist={() => toggleWishlistHandler(data)}
                  togglingWishlist={
                    props.isAddingToWishlist[data.showId] ||
                    props.isRemovingFromWishlist[data.showId]
                  }
                  inWishlist={props.wishlist.hasOwnProperty(data.showId)}
                  handleShow={handleShow}
                  dateString={dateString}
                />
              ) : (
                <EventThumb
                  item={data}
                  key={data.showId}
                  onAddToCart={e => addToCartHandler(e, data)}
                  addingToCart={props.isAddingToCart[data.showId]}
                  inCart={props.cart.hasOwnProperty(data.showId)}
                  toggleWishlist={() => toggleWishlistHandler(data)}
                  togglingWishlist={
                    props.isAddingToWishlist[data.showId] ||
                    props.isRemovingFromWishlist[data.showId]
                  }
                  inWishlist={props.wishlist.hasOwnProperty(data.showId)}
                  dateString={dateString}
                />
              )}
            </AnimatedChild>
          </Col>
        ));

        return (
          <>
            <Container className={'d-lg-none'}>
              <div key={dateString} id={'header' + dateString} />
              <div>{animatedChildFunc}</div>
            </Container>
            <Container
              className={styles.ShowCard + ' mt-4 mb-2 d-none d-lg-block'}
            >
              <div key={dateString} id={'header' + dateString} />
              <Row>
                <Col
                  className={
                    Kinostyles.ListHeadlineCenterBig +
                    ' mt-4 ' +
                    Kinostyles.TextUnderlined
                  }
                >
                  {dateString}
                </Col>
              </Row>
              <br />
              <Row>
                {animatedChildFunc}
                {/* p.showData.length === 1 ? (
                <>
                  <Col lg={4}>
                    <br />
                    <br /><br />
                    <Image src={empty_show_animation} className={styles.Image} />
                  </Col>
                  <Col lg={4}>
                  <br /><br /><br />
                  <Image src={empty_show_animation} className={styles.Image} />
                  </Col>
                </>
              ): ( p.showData.length === 2 ? (
                <>
                  <Col lg={4}>
                  <br /><br /><br />
                  <Image src={empty_show_animation} className={styles.Image} />
                  </Col>
                </>
              ):'') */}
              </Row>
            </Container>
          </>
        );
      });
    } else {
      if (window.location.pathname.includes('flohmarkt')) {
        return <div>Empty events</div>;
      } else {
        return (
          <section className={styles.Grid}>
            <h1> Kinoprogramm</h1>
            <AnimatedChild className={styles.Column} key={'1'}>
              <div>
                <div>
                  {/*<div className={stylesShowThumb.ImageBox}>
                    <img
                      src={coverAutokinoLocation}
                      //src={imagePath}//{imagePath}
                      alt="Autokino Wien Location"
                      className={stylesShowThumb.Image}
                    />
                  </div> */}
                  <div className={stylesShowThumb.Info}>
                    <div className={stylesShowThumb.MovieInfo}>
                      <h3 className={stylesShowThumb.TitleBig}>
                        Die Saison 2024 ist vorbei – Winterpause im Autokino
                      </h3>
                      {/* <h3 className={stylesShowThumb.EventStart}>
                        
        </h3> */}
                    </div>
                    <div className={stylesShowThumb.MovieDesc}>
                      Das Autokino verabschiedet sich in die Winterpause und wir
                      bedanken uns herzlich für die großartige Saison 2024!
                      <br /> <br />
                      Wir freuen uns schon darauf dich im Frühling 2025 wieder
                      willkommen zu heißen. Bis dahin bleibt der Flohmarkt für
                      dich geöffnet und findet weiterhin jeden Sonntag statt –
                      aktuell sogar mit Schnäppchen Monaten!
                      <br />
                      Bitte beachte, dass der Flohmarkt im Jänner geschlossen
                      ist und ab Februar wieder regulär öffnet.
                    </div>
                    <br />
                    <Row>
                      {
                        <div className={stylesActionBox.Buttons + ' mt-2 ml-2'}>
                          <Link
                            to="./flohmarkt-wien"
                            title="Flohmarkt Autokino Wien"
                            className={Kinostyles.CTAButton}
                          >
                            {' '}
                            Zum Flohmarkt
                          </Link>
                        </div>
                      }
                    </Row>
                    <br />
                    <div className={stylesShowThumb.MovieDesc}>
                      Möchtest du über Neuigkeiten und den Start der nächsten
                      Saison informiert werden? Dann folge uns gerne auf
                      Instagram, Facebook und Tiktok oder melde dich gratis für
                      unser Newsletter an! So verpasst du keine Updates und
                      erhältst sofort Bescheid, sobald der Termin für den
                      Saisonbeginn 2025 feststeht!
                    </div>
                    <br />
                    <div className={stylesActionBox.Buttons}>
                      <NewsletterSignup />
                    </div>
                  </div>
                </div>
              </div>
            </AnimatedChild>
          </section>
        );
      }
    }
  };

  const renderEmptyShow = () => {
    // console.log('!!!! Render Shows  = ' +props.shows[0].movieId +',name = ' +props.shows[0].movieName);
    if (!window.location.pathname.includes('flohmarkt')) {
      return (
        <ResponsiveWrapper>
          <AnimatedContainer
            pose="enter"
            // pose={isOpened ? 'enter' : 'leave'}
            className={styles.Row}
            withParent={false}
          >
            <section className={styles.Grid}>
              <AnimatedChild className={styles.Column} key={'1'}>
                <div>
                  <div>
                    <div className={stylesShowThumb.Info}>
                      <div className={styles.MovieInfo}>
                        <h3 className={stylesShowThumb.TitleBig}>
                          KINO-SAISONENDE 2022 / WIR SEHEN UNS 2023 / FLOHMARKT
                          OFFEN!
                        </h3>
                      </div>
                      <div className={stylesShowThumb.MovieDesc}>
                        Wir bedanken uns bei allen AUTOKINO-Besucher:innen für
                        eine tolle Saison 2022. Unsere Erwartungen wurden
                        übertroffen, deswegen planen wir bereits ganz motiviert
                        für 2023! Apropos – wir starten unsere Projektoren
                        wieder im März/April 2023 – spannende Filme stehen an
                        und Du kannst Dich bereits jetzt unter dem Menüpunkt
                        „Bald im Kino“ dafür vormerken. Um den exakten
                        Saisonstart 2023 zu erfahren, solltest Du am besten
                        unserem Newsletter bzw. das wöchentliche Filmprogramm
                        abonnieren.
                        <br />
                        Alternativ folge uns ganz einfach auf Facebook,
                        Instagram oder TikTok.
                        <br />
                        Falls Du Deinen Liebsten ein besonderes Geschenk machen
                        möchtest – Autokino Gutscheine eignen sich für jeden
                        Anlass!
                        <br />
                        <br />
                        <b>
                          Unser beliebter Sonntags-Flohmarkt bleibt natürlich
                          weiterhin offen!
                        </b>
                        <br />
                        <b>Erster Kinderflohmarkt 2023 am 26.03.2023</b>
                        <br />
                      </div>
                      <div className={styles.Flex}>
                        <FilmprogrammSignup />
                        &nbsp;
                        <NewsletterSignup />
                      </div>
                      <div className={styles.Flex}>
                        <div className={stylesActionBox.Buttons + ' mt-2 mr-2'}>
                          <Link
                            to="./kinogutschein"
                            title="Gutschein kaufen"
                            className={Kinostyles.CTAButton}
                          >
                            Gutschein kaufen
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimatedChild>
            </section>
            {/*} <section className={styles.Grid}>
              <AnimatedChild className={styles.Column} key={'1'}>
                <div>
                  <div>
                    <div className={stylesShowThumb.ImageBox}>
                      <img
                        //src={coverAutokino2022Opening}
                        src={coverFahrradTicket}
                        //src={imagePath}//{imagePath}
                        alt="Autokino Wien Location"
                        className={stylesShowThumb.Image}
                      />
                    </div>
                    <div className={stylesShowThumb.Info}>
                      <div className={styles.MovieInfo}>
                        <h3 className={stylesShowThumb.TitleBig}>
                          NEU!!! BIKE-/FUßGÄNGER-TICKET
                          </h3>
                        </div>
                        <div className={stylesShowThumb.MovieDetail}>
                          <div className={stylesShowThumb.MovieDetailText}>
                            Erlebe die besten Filme auf Österreichs größter Leinwand nun auch mit Deinem Bike bzw. zu Fuß
                          </div>
                        </div>
                        <div className={stylesShowThumb.MovieDesc}>
                          Ab sofort kannst Du schöne Kino-Sommerabende bei uns nicht nur mit dem Auto, sondern auch mit dem Fahrrad, Motorrad oder zu Fuß genießen! <br />
                          Natürlich ist ein Leihradio und Liegestuhl im Ticketpreis inkludiert.<br />
                          Hollywood unter Sternen war noch nie so nah 😉<br/>
                          Unsere leckeren, kühlen Cocktails und aktuellsten Blockbuster warten schon auf Dich!<br />
                          Alle weiteren Infos findest Du direkt bei der Ticketbuchung des jeweiligen Films oder <a href="/autokino-wien/fragen-antworten-kinobesucher-fahrrad-faqs">HIER</a><br />
                        </div>
                        <div className={stylesShowThumb.CTAHeadline}>
                          Bis bald im Autokino Wien!  
                        </div>
                 {
                      <div className={styles.MovieInfo}>
                      <h3 className={stylesShowThumb.TitleBig}>
                          Das Motto im Jahr 2022: "Samstag ist Autokino-Tag"
                        </h3>
                      </div>
                      <div className={stylesShowThumb.MovieDetail}>
                        <div className={stylesShowThumb.MovieDetailText}>
                          Die besten Filme auf Österreichs größter Leinwand!
                        </div>
                      </div>
                      <div className={stylesShowThumb.MovieDesc}>
                        Lass Dich dazu von unserem kulinarischen Angebot samt frisch zubereiteten Cocktails verwöhnen.
                        <br />Sichere Dir jetzt gleich Kinotickets und gönn dir eine tolle und unvergessliche Zeit bei uns im (Auto) Kino Wien.
                       </div>
                      <div className={stylesShowThumb.CTAHeadline}>
                        Bis bald im Autokino Wien!  
                      </div> }
                      <div className={styles.Flex}>
                        {
                          /*
                          <div className={stylesActionBox.Buttons + ' mt-2 mr-2'}>
                            <Link
                              to="./kinogutschein"
                              title="Gutschein kaufen"
                              className="btn btn-first"
                            >
                              {' '}
                              Gutschein kaufen
                            </Link>
                          </div>
                        }
                        <NewsletterSignup />
                        &nbsp;
                        <FilmprogrammSignup />
                      </div>
                    </div>
                  </div>
                </div>
              </AnimatedChild>
                      </section> */}
            {/*} <section className={styles.GridMargin}>
            <hr className={styles.TextSeperator} />
            <div className={stylesShowThumb.InfoDesc}>
              <b>Covid-Info:</b> Aktuell gibt es bei uns <b>keine Einschränkungen</b>, wir bitten Dich jedoch in Bereichen mit vermehrtem Kundenaufkommen einen Mund-Nasen-Schutz zu tragen.
            </div>
            <hr className={styles.TextSeperator} />
                      </section> */}
          </AnimatedContainer>
        </ResponsiveWrapper>
      );
    } else {
      return '';
    }
  };

  const renderCharityInfo = () => {
    // console.log('!!!! Render Shows  = ' +props.shows[0].movieId +',name = ' +props.shows[0].movieName);
    if (!window.location.pathname.includes('flohmarkt')) {
      return (
        <ResponsiveWrapper>
          <AnimatedContainer
            pose="enter"
            // pose={isOpened ? 'enter' : 'leave'}
            className={styles.Row}
            withParent={false}
          >
            <section className={styles.Grid}>
              <AnimatedChild className={styles.Column} key={'1'}>
                <div>
                  <div>
                    <div className={stylesShowThumb.ImageBox}>
                      <img
                        src={imageCharity2023}
                        //src={imagePath}//{imagePath}
                        alt="Autokino Charity Licht ins Dunkel 2023"
                        className={stylesShowThumb.Image}
                      />
                    </div>
                    <div className={stylesShowThumb.Info}>
                      <div className={stylesShowThumb.MovieInfo}>
                        <h3 className={stylesShowThumb.TitleBig}>
                          „CHARITY X-MAS“ im Autokino Wien
                        </h3>
                      </div>
                      <div className={stylesShowThumb.MovieDesc}>
                        <b>
                          Die Autokino-Crew sagt DANKE und öffnet nochmals für
                          den guten Zweck!
                          <br />
                          (100% des Reinerlöses werden gespendet)
                        </b>
                        <br />
                        Traditioneller Weise hat das Autokino über Weihnachten
                        geschlossen, dieses Jahr wird es anders sein, denn wir
                        öffnen für den guten Zweck!
                        <br />
                        <br />
                        Am 22.12.2023 werfen wir die Projektoren noch einmal für
                        euch an und laden euch ein, noch einen weihnachtlichen
                        Kinoabend mit uns zu verbringen!
                        <br />
                        Die Projektoren laufen aber nicht nur für alle
                        Besucher:innen, sondern auch für alldiejenigen, denen es
                        nicht so gut geht, denn der Reinerlös dieses Abends wird
                        zu 100% an <b>LICHT INS DUNKEL</b> gespendet!
                        <br />
                        <br />
                        Zusätzlich zum klassischen Kinosetup erwarten Euch
                        natürlich weihnachtliche Extras, wie heiße Getränke,
                        Maroni und entsprechende Kinofilme! <br />
                        <br />
                        Schnappt euch also eure Liebsten, eine kuschelige Decke
                        und lasst uns zusammen einen weihnachtlichen Kinoabend
                        genießen und gleichzeitig etwas Gutes tun!
                        <br />
                        <br />
                        Am besten Deinen Lieblingsfilm gleich jetzt hier unten
                        buchen!
                        <br />
                        <br />
                        Wir freuen uns auf euch!
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </AnimatedChild>
            </section>
          </AnimatedContainer>
        </ResponsiveWrapper>
      );
    } else {
      return '';
    }
  };

  const renderEvents = () => {
    // console.log('!!!! Render Shows  = ' +props.shows[0].movieId +',name = ' +props.shows[0].movieName);
    if (props.events != null && props.events.length > 0) {
      return props.events.map((p, index) => {
        const dateString = p.dateString;
        console.log('events inMAPP dateString = ' + dateString);
        const animatedChildFunc = p.showData.map((data, index) => (
          <Col xs={12} lg={4}>
            <AnimatedChild className={styles.Column} key={data.showId}>
              {data.movie !== null ? (
                <ShowThumb
                  item={data}
                  key={data.showId}
                  onAddToCart={e => addToCartHandler(e, data)}
                  addingToCart={props.isAddingToCart[data.showId]}
                  inCart={props.cart.hasOwnProperty(data.showId)}
                  toggleWishlist={() => toggleWishlistHandler(data)}
                  togglingWishlist={
                    props.isAddingToWishlist[data.showId] ||
                    props.isRemovingFromWishlist[data.showId]
                  }
                  inWishlist={props.wishlist.hasOwnProperty(data.showId)}
                  dateString={dateString}
                />
              ) : (
                <EventThumb
                  item={data}
                  key={data.showId}
                  onAddToCart={e => addToCartHandler(e, data)}
                  addingToCart={props.isAddingToCart[data.showId]}
                  inCart={props.cart.hasOwnProperty(data.showId)}
                  toggleWishlist={() => toggleWishlistHandler(data)}
                  togglingWishlist={
                    props.isAddingToWishlist[data.showId] ||
                    props.isRemovingFromWishlist[data.showId]
                  }
                  inWishlist={props.wishlist.hasOwnProperty(data.showId)}
                  dateString={dateString}
                />
              )}
            </AnimatedChild>
          </Col>
        ));

        return (
          <Container key={dateString}>
            <Row>
              <Col className={'mt-4 mb-0'}>
                <h3 className={styles.HeadingBold}>{dateString}</h3>
              </Col>
              <hr className={styles.DaySeperator} />
            </Row>
            <Row>{animatedChildFunc}</Row>
          </Container>
        );
      });
    }
  };

  // const ID = props.id;
  // render the Spinner initially
  let showsList = <Spinner />;
  // render the real component if
  // products are received and valid

  // console.log('aaaaaaaaaaaaa = ' +props.shows[ID] +', propListId = ' +ID);
  showsList = (
    <section className={styles.Grid + ' ' + styles.BackgroundGray}>
      <ResponsiveWrapper>
        <AnimatedContainer
          pose="enter"
          // pose={isOpened ? 'enter' : 'leave'}
          className={styles.Row}
          withParent={false}
        >
          {/* renderEmptyShow() */}
          {/* renderCharityInfo() */}
          <br />
          <h2
            className={' p-4 ' + Kinostyles.ListHeadline}
            id="headerprogram"
            name="headerprogram"
          >
            {props.shows[0] != null
              ? props.shows[0].showData[0].movie !== null
                ? 'Kinoprogramm'
                : props.shows[0].showData[0].event !== null &&
                  props.shows[0].showData[0].event.eventType === 'automarkt'
                ? 'kommende Automärkte'
                : props.shows[0].showData[0].event !== null &&
                  props.shows[0].showData[0].event.eventType === 'publicevent'
                ? 'Veranstaltungen'
                : props.shows.length === 1 &&
                  props.shows[0].showData[0].event.eventId === 12
                ? 'Jetzt gleich Standplatz sichern'
                : 'Flohmarktkalender'
              : ''}{' '}
          </h2>
          {renderShows()}
        </AnimatedContainer>
        <br />
        {/* renderNewsletterSignup() */}
        <Modal show={showNewsletterSignup}>
          <Modal.Header className={styles.ModalHeader}>
            <Modal.Title>Meine Filme vormerken</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.ModalBody}>
            <Form.Group controlId="info">
              Ich möchte mich für folgende Filme vormerken um rechtzeitig per
              Email informiert zu werden sobald eine Vorstellung im Autokino
              Wien verfügbar ist.
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label sm="6">Emailadresse*:</Form.Label>
              <Form.Control
                sm="6"
                type="email"
                onChange={e => setField('email', e.target.value)}
                defaultValue={emailadresse}
                placeholder="Emailadresse"
                isInvalid={!!errors.email}
              />
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
            {renderMovieListCheckboxes()}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              type="submit"
              onClick={e => submitMovieSubscription(e)}
              className={'btn btn-first'}
            >
              Jetzt vormerken
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={() => handleShow(false)}
              className={'btn btn-first'}
            >
              Schließen
            </Button>
          </Modal.Footer>
        </Modal>
      </ResponsiveWrapper>
    </section>
  );
  console.log('aaaaaaaaaaaaa');
  return <div>{showsList}</div>;
};

ShowsList.propTypes = {
  shows: PropTypes.array,
  wishlist: PropTypes.object,
  isAddingToWishlist: PropTypes.objectOf(PropTypes.bool),
  isRemovingFromWishlist: PropTypes.objectOf(PropTypes.bool),
  cart: PropTypes.object,
  isLoading: PropTypes.objectOf(PropTypes.bool),
  isAddingToCart: PropTypes.objectOf(PropTypes.bool),
  onAddToCart: PropTypes.func,
  onAddToWishlist: PropTypes.func,
  onRemoveFromWishlist: PropTypes.func,
  saveMovieReminderSubscription: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    shows: state.shows.shows,
    wishlist: state.wishlist.wishlist,
    isAddingToWishlist: state.wishlist.isAddingToWishlist,
    isRemovingFromWishlist: state.wishlist.isRemovingFromWishlist,
    cart: state.cart.cart,
    isLoading: state.shows.isLoading,
    isAddingToCart: state.cart.isAddingToCart,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddToCart: item => dispatch(actions.addToCart(item)),
    onRemoveFromWishlist: id => dispatch(actions.removeFromWishlist(id)),
    onAddToWishlist: item => dispatch(actions.addToWishlist(item)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowsList);

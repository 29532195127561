export const HOME = process.env.PUBLIC_URL + '/';
export const LANDING = process.env.PUBLIC_URL + '/kinoprogramm-wien';
export const LANDING_HEADER_SECTION =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/?selectshow=headerprogram';
export const CATEGORY = process.env.PUBLIC_URL + '/category';
export const ITEM = process.env.PUBLIC_URL + '/item';
export const SHOW = process.env.PUBLIC_URL + '/show';
export const MOVIE_SHOW =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/kinofilm';
export const MOVIE_DETAIL =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/kinoprogramm';
export const EVENT_ITEM = process.env.PUBLIC_URL + '/event';
export const CART = process.env.PUBLIC_URL + '/cart';
export const WISHLIST = process.env.PUBLIC_URL + '/wishlist';
export const NOT_FOUND = process.env.PUBLIC_URL + '/404';
export const CHECKOUT = process.env.PUBLIC_URL + '/checkout';
export const PAYMENT = process.env.PUBLIC_URL + '/pspresult';
export const GIFTCARD = process.env.PUBLIC_URL + '/kinogutschein-kaufen';
export const EVENT = process.env.PUBLIC_URL + '/flohmarkt-wien';
export const EVENT_FLOHMARKT_DETAIL =
  process.env.PUBLIC_URL + '/flohmarktprogramm-wien';
export const PUBLIC_EVENT_DETAIL = process.env.PUBLIC_URL + '/veranstaltungen';
export const AUTOMARKT = process.env.PUBLIC_URL + '/automarkt';
export const AGB = process.env.PUBLIC_URL + '/agb';
export const SHOW_NOT_FOUND =
  process.env.PUBLIC_URL + '/vorstellung-nicht-gefunden';
export const UPCOMING_MOVIES =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/demnaechst-im-kino';
export const UPCOMING_MOVIES_GENRE =
  process.env.PUBLIC_URL +
  '/kinoprogramm-wien/demnaechst-im-kino?movietype=genres';
export const UPCOMING_MOVIES_OV =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/demnaechst-im-kino?movietype=OV';
export const SICHERES_KINO = process.env.PUBLIC_URL + '/sicheres-kino';
export const CHANUKKA = process.env.PUBLIC_URL + '/chanukka2021';
export const BLOGAUTOKINO = process.env.PUBLIC_URL + '/blog-autokino';
export const BLOGFLOHMARKT = process.env.PUBLIC_URL + '/flohmarkt-wien/blog';
export const KINDERFLOHMARKT =
  process.env.PUBLIC_URL + '/flohmarkt-wien/kinderflohmarkt-wien-sonntag';
export const FLOHMARKT_GESCHICHTE =
  process.env.PUBLIC_URL + '/flohmarkt-wien/geschichte-flohmarkt-autokino';
export const FLOHMARKT_KUNDENLIEBE =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/kundenliebe-oesterreichs-bester-flohmarkt';
export const FLOHMARKT_BESUCHER_TIPPS =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/tipps-und-tricks-fuer-flohmarkt-besucher';
export const FLOHMARKT_VERKAEUFER_TIPPS =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/tipps-tricks-fuer-deinen-erfolgreichen-flohmarkt-verkauf';
export const FLOHMARKT_FAQ =
  process.env.PUBLIC_URL + '/flohmarkt-wien/faqs-flohmarkt-wien-sonntag';
export const FLOHMARKT_MARKTORDNUNG =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-regeln-marktordnung';
export const FLOHMARKT_CHECKLIST =
  process.env.PUBLIC_URL + '/flohmarkt-wien/checklist-fuer-flohmarkt-verkaufer';
export const FLOHMARKT_ANREISE =
  process.env.PUBLIC_URL + '/flohmarkt-wien/anreise-gratis-parken';
export const FLOHMARKT_FOTOS =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-heute-fotos';
export const FLOHMARKT_STANDGEBUEHREN =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/flohmarkt-standplaetze-standgebuehren-platzmiete';
export const FLOHMARKT_STANDPLATZ_BUCHEN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/?selectshow=headerprogram';
export const AUTOKINO_BIKE_FAQ =
  process.env.PUBLIC_URL +
  '/autokino-wien/fragen-antworten-kinobesucher-fahrrad-faqs';
export const CUSTOMER_FEEDBACK_FORM = process.env.PUBLIC_URL + '/feedback';
export const CUSTOMER_FEEDBACK_FORM_SUCCESS =
  process.env.PUBLIC_URL + '/feedback_success';
export const FLOHMARKT_MISTELBACH =
  process.env.PUBLIC_URL + '/flohmarkt-mistelbach';
export const FLOHMARKT_JOBS_AUTOKINO =
  process.env.PUBLIC_URL + '/flohmarkt-wien/jobs';
export const FLOHMARKT_NIEDEROESTERREICH =
  process.env.PUBLIC_URL + '/flohmarkt-niederoesterreich';
export const FLOHMARKT_GAENSERNDORF =
  process.env.PUBLIC_URL + '/flohmarkt-gaenserndorf';

export const EVENT_LOCATION = process.env.PUBLIC_URL + '/eventlocation';
export const ANREISE_AUTOKINO = process.env.PUBLIC_URL + '/kino/anreise';
export const FAQ_AUTOKINO = process.env.PUBLIC_URL + '/kino/faqs';
export const HAUSTIERE_AUTOKINO =
  process.env.PUBLIC_URL + '/kino/hund-katze-haustiere';
export const JOBS_AUTOKINO = process.env.PUBLIC_URL + '/kino/jobs';
export const PHILOSOPHIE_AUTOKINO =
  process.env.PUBLIC_URL + '/kino/philosophie';
export const OPENAIR_AUTOKINO = process.env.PUBLIC_URL + '/kino/open-air-kino';
export const SIEGERKINOTEST_AUTOKINO =
  process.env.PUBLIC_URL + '/kino/sieger-kinotest2022';
export const PARTNER_AUTOKINO = process.env.PUBLIC_URL + '/kino/partner';
export const DAS_AUTOKINO = process.env.PUBLIC_URL + '/kino-erlebnis';

export const GENERIC_LANDING_PAGE =
  process.env.PUBLIC_URL + '/test-landing-page';
export const GENERIC_LANDING_PAGE_EVENT_LOCATION =
  process.env.PUBLIC_URL + '/event-test-landing-page';
export const GENERIC_LANDING_PAGE_AUTOKINO =
  process.env.PUBLIC_URL + '/autokino-test-landing-page';
export const GENERIC_LANDING_PAGE_KINDERFLOHMARKT =
  process.env.PUBLIC_URL + '/kinderflohmarkt-test-landing-page';

export const ORDER_STORNO = process.env.PUBLIC_URL + '/bestellung-stornieren';

export const MINIONS4_AUTOKINO =
  process.env.PUBLIC_URL +
  '/kinoprogramm-minions-4-ich_einfach_unverbesserlich_4';

export const FLOHMARKT_WINTERPAUSE =
  process.env.PUBLIC_URL + '/flohmarkt-wien/winterpause';
export const AUTOKINO_WINTERPAUSE =
  process.env.PUBLIC_URL + '/autokino-winterpause';

export const JOBAPPLICATION_SUCCESS =
  process.env.PUBLIC_URL + '/bewerbung-autokino-abgeschickt';

export const ANFAENGER_TIPPS_FLOHMARKT =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-tipps-anfaenger';
export const ANMELDEN_FLOHMARKT =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-anmelden';
export const AUSSTELLER_WISSEN_LEXIKON =
  process.env.PUBLIC_URL + '/flohmarkt-aussteller-wissen';
export const BABYFLOHMARKT = process.env.PUBLIC_URL + '/babyflohmarkt';
export const BESTEN_FLOHMAERKTE_WIEN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/besten-flohmaerkte-wien';
export const BESTE_FLOHMAERKTE_UEBERSICHT =
  process.env.PUBLIC_URL + '/flohmarkt-wien/beste-flohmarkt-uebersicht';
export const DIEBSTAHL =
  process.env.PUBLIC_URL + '/flohmarkt-wien/diebstahl-vermeiden-flohmarkt';
export const DURCHSCHNITTLICHE_EINNAHMEN =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/durchschnittliche-flohmarkt-einnahmen';
export const ERFOLGREICH_HANDELN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/erfolgreich-handeln-am-flohmarkt';
export const FLOHMARKT_IN_DER_NAEHE =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-in-der-naehe';
export const LEIHTISCHE =
  process.env.PUBLIC_URL + '/flohmarkt-wien/tischleihe-tischmiete-flohmarkt';
export const FLOHMARKT_LISTE =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-liste';
export const FLOHMARKT_OHNE_STANDGEBUEHR =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-ohne-standgebuehr';
export const FLOHMARKT_ONLINE_VERGLEICHEN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-online-vergleichen';
export const FLOHMARKT_PLATZ_RESERVIEREN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-platz-reservieren';
export const PREISGESTALTUNG =
  process.env.PUBLIC_URL + '/flohmarkt-wien/preisgestaltung-deiner-ware';
export const GELD_VERDIENEN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt_geld_verdienen';
export const GENEHMIGUNG_ANMELDUNG =
  process.env.PUBLIC_URL + '/flohmarkt-wien/genehmigung-anmeldung';
export const GEWERBE_GEWERBESCHEIN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/gewerbe-gewerbeschein';
export const GROESSTER_FLOHMARKT_WIEN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/groessten-flohmaerkte-wien';
export const HOBBY_FLOHMARKT =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/hobby-flohmarkt-verkauefer-soziales';
export const JETZT_STANDPLATZ_BUCHEN =
  process.env.PUBLIC_URL + '/jetzt-flohmarktstand-buchen';
export const KINDER_VERKAEUFER =
  process.env.PUBLIC_URL + '/kinderflohmarkt/kinder-als-verkauefer-learnings';
export const KINDERFLOHMARKT_CHECKLISTE =
  process.env.PUBLIC_URL + '/kinderflohmarkt/kinderflohmarkt-checkliste';
export const TIPPS_PREISE =
  process.env.PUBLIC_URL + '/kinderflohmarkt/tipps-preise-deiner-ware';
export const FLOHMARKT_PREISE =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-wie-kleidung-verkaufen';
export const FLOHMARKT_MITMACHEN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-mitmachen';
export const FLOHMARKT_NACHHALTIGKEIT =
  process.env.PUBLIC_URL + '/flohmarkt-wien/nachhaltigkeit';
export const FLOHMARKT_NEUWARE =
  process.env.PUBLIC_URL + '/flohmarkt-wien/neuware-verkaufen';
export const FLOHMARKT_TOOLS =
  process.env.PUBLIC_URL + '/flohmarkt-wien/nuetzliche-hilfsmittel-flohmarkt';
export const PACKLISTE_FLOHMARKT =
  process.env.PUBLIC_URL + '/flohmarkt-wien/packliste-flohmarkt';
export const PARKPLATZ_FLOHMARKT =
  process.env.PUBLIC_URL + '/flohmarkt-wien/verkaufsplatzgroße_vergleich';
export const PREISSCHILDER_FLOHMARKT =
  process.env.PUBLIC_URL + '/flohmarkt-wien/preis-schilder';
export const REGEN_FLOHMARKT =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-bei-regen';
export const WAREN_REINIGEN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarktware-aufbereiten-reinigen';
export const FLOHMARKT_ORGANISATION =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/orgnisation-und-sortieren-meiner-artikel-vorab';
export const STAND_GESTALTEN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/stand-gestalten-praesentieren';
export const STROMTESTER =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/stromtester-elektroartikel-verkaufen-flohmarkt';
export const TIPPS_KINDERFLOHMARKT =
  process.env.PUBLIC_URL +
  '/kinderflohmarkt/tipps-fuer-verkauf-von-kindersachen';
export const UEBRIG_GEBLIEBENE_WAREN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/uebergebliebene-ware-was-tuen';
export const UMGANG_KUNDEN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/umgang-flohmarkt-kunden';
export const VORTEILE_AUSSTELLER =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/vorteile-aussteller-flohmarkt-autokino';
export const WARE_LAGERN =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/flohmarkt-ware-lagern-container-mieten';
export const GUTE_WAREN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/was-verkauft-sich-gut';
export const WECHSELGELD =
  process.env.PUBLIC_URL + '/flohmarkt-wien/wechselgeld';
export const RICHTIGER_FLOHMARKT =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/wie-finde-ich-den-richtigen-flohmarkt';

export const FLOHMARKT_WIE =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-wie';

export const FLOHMARKT_TERMINE =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkttermine';

export const JURASSIC_WORLD =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/jurassic-world-die-wiedergeburt';

export const SUPERMAN_LEGACY =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/superman-legacy';
export const FANTASTIC_FOUR =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/fantastic-four';
export const DIE_SCHLUEMPFE =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/die-schluempfe-der-grosse-kinofilm';

export const GANGSTER_GANG_2 =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/die-gangster-gang-2';

export const F1_DERFILM =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/F1-der-film-kino';

export const AUSFLUGSZIEL_WIEN =
  process.env.PUBLIC_URL + '/kino/ausflugsziel-unternehmung-sehenswuerdigkeit-wien'

export const DATE_IDEEN_WIEN =
  process.env.PUBLIC_URL + '/kino/dating-ideen-wien-location'

export const FAMILIENAUSFLUG_KINO =
  process.env.PUBLIC_URL + '/kino/familienausflug-kinder-ausflug'
export const HEUTE_IM_KINO =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/heute-im-kino'
export const KINDER_KINO =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/kinder-kino-kinoprogramm-fuer-kinder'
export const MAEDELSABEND_KINO =
  process.env.PUBLIC_URL + '/kino/maedelsabend-wien'
export const TOPKINO_2025 =
  process.env.PUBLIC_URL + '/kino/top-kinofilme-2025'
export const BABYKINO =
  process.env.PUBLIC_URL + '/kino/babykino'


export const FACEBOOK =
  process.env.PUBLIC_URL + '/facebook'
export const INSTAGRAM =
  process.env.PUBLIC_URL + '/instagram'
export const TIKTOK =
  process.env.PUBLIC_URL + '/tiktok'
export const FACEBOOK_FLOHMARKT =
  process.env.PUBLIC_URL + '/facebookflohmarkt'
export const INSTAGRAM_FLOHMARKT =
  process.env.PUBLIC_URL + '/instagramflohmarkt'
export const FACEBOOK_KINDERFLOHMARKT =
  process.env.PUBLIC_URL + '/facebookkinderflohmarkt'
export const INSTAGRAM_KINDERFLOHMARKT =
  process.env.PUBLIC_URL + '/instagramkinderflohmarkt'



import React from 'react';

import Logo from '../UI/Logo/Logo';
import styles from './Copyright.module.css';
import LinkItem from '../UI/LinkItem/LinkItem';
import { getClassName } from '../../components/UI/ResponsiveWrapper/ResponsiveWrapper';
import fblogoSvg from '../../assets/images/autokino/facebook_icon.svg';
import instalogoSvg from '../../assets/images/autokino/insta_icon.svg';
import tiktoklogoSvg from '../../assets/images/autokino/tiktok_icon.svg';
import * as ROUTES from '../../constants/routes';

/**
 * TODO
 * Add social network icons
 */

const Copyright = () => {
  let facebookurl = ROUTES.FACEBOOK;
  let instaurl = ROUTES.INSTAGRAM;
  let tiktokurl = ROUTES.TIKTOK;
  if (window.location.pathname.includes('flohmarkt')) {
    if (window.location.pathname.includes('kinderflohmarkt')) {
      facebookurl = ROUTES.FACEBOOK_KINDERFLOHMARKT;
      instaurl = ROUTES.INSTAGRAM_KINDERFLOHMARKT;
    } else {
      facebookurl = ROUTES.FACEBOOK_FLOHMARKT;
      instaurl = ROUTES.INSTAGRAM_FLOHMARKT;
    }
    tiktokurl = null;
  }
  return (
    <div className={styles.Copyright}>
      <div className={styles.Container + ` ${getClassName()}`}>
        <div className={styles.InnerContainer}>
          &copy; 2025
          <div className={styles.Logo}>
            <Logo height={'15'} width={'28'} />
          </div>
          Autokino Wien
          <div>
            <a href={facebookurl}>
              <img
                src={fblogoSvg}
                width={30}
                height={30}
                className='ml-2'
                alt="Facebook"
              />
            </a>
            <a href={instaurl}>
              <img
                src={instalogoSvg}
                width={30}
                height={30}
                className='ml-2'
                alt="Instagram"
              />
            </a>
            {tiktokurl != null ? (<a href={ROUTES.TIKTOK}>
              <img
                src={tiktoklogoSvg}
                width={30}
                height={30}
                className='ml-2 mr-2'
                alt="TikTok Autokino"
              />
            </a>) : ''}
          </div>
        </div>
        <div className={[styles.InnerContainer, styles.Links].join(' ')}>
          <LinkItem url="/privacy" alt="Datenschutzerkl&auml;rung">
            Datenschutzerkl&auml;rung
          </LinkItem>
          <LinkItem url="/agb" alt="AGBs">
            AGBs
          </LinkItem>
        </div>
      </div>
    </div>
  );
};

export default Copyright;

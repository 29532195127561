import * as ROUTES from '../constants/routes';

export const getAutokinoLDSnippet = () => {
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'Autokino Wien',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Autokinostraße 2',
      addressLocality: 'Groß-Enzersdorf',
      addressRegion: 'Niederösterreich',
      postalCode: '2301',
    },
    url: 'https://autokino.at',
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 48.20347547541199,
      longitude: 16.539275978356955,
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: 4.6,
      reviewCount: 1448,
    },
  };
  console.log('getAutokinoLDSnippet result: ' + JSON.stringify(ldJson));
  return <script type="application/ld+json">{JSON.stringify(ldJson)}</script>;
};

export const getFlohmarktLDSnippet = () => {
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'Flohmarkt im Autokino Wien',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Autokinostraße 2',
      addressLocality: 'Groß-Enzersdorf',
      addressRegion: 'Niederösterreich',
      postalCode: '2301',
    },
    url: 'https://autokino.at/flohmarkt-wien',
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 48.20356953311007,
      longitude: 16.539701324969187,
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: 4.4,
      reviewCount: 577,
    },
  };
  return <script type="application/ld+json">{JSON.stringify(ldJson)}</script>;
};

export const getKinderFlohmarktLDSnippet = () => {
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'Kinderflohmarkt Wien im Autokino',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Autokinostraße 2',
      addressLocality: 'Groß-Enzersdorf',
      addressRegion: 'Niederösterreich',
      postalCode: '2301',
    },
    url: 'https://autokino.at/flohmarkt-wien/kinderflohmarkt-wien-sonntag',
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 48.20360745498078,
      longitude: 16.538637327821192,
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: 4.9,
      reviewCount: 118,
    },
  };
  return <script type="application/ld+json">{JSON.stringify(ldJson)}</script>;
};

export function getJSONData(value, index, array) {
  var array = [];
  if (value.showData != null && value.showData.length > 0) {
    array = value.showData.map((value2, index2, array2) => {
      if (value2) {
        if (value2.movie) {
          return renderMovieEventJsonLdJSON(value2);
        } else if (value2.event) {
          if (value2.event.eventType === 'publicevent') {
            return renderPublicEventJsonLdJSON(value2);
          } else if (value2.showName.indexOf('Kinderflohmarkt')) {
            return renderKinderFlohmarktEventJsonLdJSON(value2);
          } else {
            return renderFlohmarktEventJsonLdJSON(value2);
          }
        }
      }
    });
  }
  return array;
}

const renderMovieEventJsonLdJSON = showData => {
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'ScreeningEvent',
    name: showData.showName,
    startDate: showData.dateISO,
    endDate: showData.dateEndISO,
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    eventStatus: 'https://schema.org/EventScheduled',
    location: {
      '@type': 'MovieTheater',
      '@id': 'https://autokino.at',
      name: 'Autokino Wien',
      image: 'https://autokino.at/Autokino-Logo_dark_RGB.svg',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Autokinostraße 2',
        addressLocality: 'Groß-Enzersdorf',
        postalCode: '2301',
        addressCountry: 'AT',
      },
    },
    image: [showData.movie.headerImagePath, showData.movie.imagePath],
    performer: {
      '@type': 'PerformingGroup',
      name: showData.movie.actors,
    },
    description: showData.movie.description,
    organizer: {
      '@type': 'Organization',
      name: 'Autokino Wien',
      url: 'https://autokino.at',
    },
    url: getDetailPath(showData),
    videoFormat: '2D',
    workPresented: {
      '@type': 'Movie',
      '@id': getDetailPath(),
      name: showData.movie.movieName,
      image: [showData.movie.headerImagePath, showData.movie.imagePath],
      description: showData.movie.description,
      director: [
        {
          '@type': 'Person',
          name: showData.movie.producer,
        },
      ],
    },
  };
  return ldJson;
};

const renderPublicEventJsonLdJSON = showData => {
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: showData.showName,
    startDate: showData.dateISO,
    endDate: showData.dateEndISO,
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    eventStatus: 'https://schema.org/EventScheduled',
    location: {
      '@type': 'MovieTheater',
      '@id': 'https://autokino.at',
      name: 'Autokino Wien',
      image: 'https://autokino.at/Autokino-Logo_dark_RGB.svg',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Autokinostraße 2',
        addressLocality: 'Groß-Enzersdorf',
        postalCode: '2301',
        addressCountry: 'AT',
      },
    },
    image: [showData.event.headerImage],
    url: getDetailPathEvent(showData),
    description: showData.event.description,
    organizer: {
      '@type': 'Organization',
      name: 'Autokino Wien',
      url: 'https://autokino.at',
    },
  };
  return ldJson;
};

const renderFlohmarktEventJsonLdJSON = showData => {
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: showData.showName,
    startDate: showData.dateISO,
    endDate: showData.dateEndISO,
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    eventStatus: 'https://schema.org/EventScheduled',
    location: {
      '@type': 'Place',
      name: 'Flohmarkt im Autokino Wien',
      image: 'https://autokino.at/Flohmarkt-Logo_RGB.svg',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Autokinostraße 2',
        addressLocality: 'Groß-Enzersdorf',
        postalCode: '2301',
        addressCountry: 'AT',
      },
    },
    image: [showData.event.headerImage],
    url: getDetailPathEvent(showData),
    description: showData.event.description,
    organizer: {
      '@type': 'Organization',
      name: 'Flohmarkt im Autokino Wien',
      url: 'https://autokino.at/flohmarkt-wien/',
    },
  };
  return ldJson;
};

const renderKinderFlohmarktEventJsonLdJSON = showData => {
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: showData.showName,
    startDate: showData.dateISO,
    endDate: showData.dateEndISO,
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    eventStatus: 'https://schema.org/EventScheduled',
    location: {
      '@type': 'Place',
      name: 'Kinderflohmarkt Wien im Autokino',
      image: 'https://autokino.at/KFM_logo_dark.png',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Autokinostraße 2',
        addressLocality: 'Groß-Enzersdorf',
        postalCode: '2301',
        addressCountry: 'AT',
      },
    },
    image: [showData.event.headerImage],
    description: showData.event.description,
    url: getDetailPathEvent(showData),
    organizer: {
      '@type': 'Organization',
      name: 'Kinderflohmarkt Wien im Autokino',
      url: 'https://autokino.at/flohmarkt-wien/kinderflohmarkt-wien-sonntag',
    },
  };
  return ldJson;
};

const getDetailPath = showData => {
  if (showData) {
    return showData.movieUrlIdentifier
      ? ROUTES.MOVIE_SHOW + '/' + showData.movieUrlIdentifier
      : ROUTES.SHOW + '/' + showData.showId;
  }
  return '';
};

const getDetailPathEvent = showData => {
  if (showData) {
    return showData.urlIdentifier
      ? showData.event != null && showData.event.eventType === 'publicevent'
        ? ROUTES.PUBLIC_EVENT_DETAIL + '/' + showData.urlIdentifier
        : ROUTES.EVENT_FLOHMARKT_DETAIL + '/' + showData.urlIdentifier
      : ROUTES.EVENT_ITEM + '/' + showData.showId;
  }
  return '';
};

export default getFlohmarktLDSnippet;

import * as actionTypes from './actionTypes';
import { addError } from './errors';
import API from '../../services/WsApi';

export const fetchShowsStart = () => {
  return {
    type: actionTypes.FETCH_SHOWS_START,
  };
};

export const fetchShowsFail = error => {
  return {
    type: actionTypes.FETCH_SHOWS_FAIL,
  };
};

export const fetchShowsSuccess = shows => {
  console.log('!!!fetchShowsSuccess');

  return {
    type: actionTypes.FETCH_SHOWS_SUCCESS,
    shows,
  };
};
export const fetchPublicEventsSuccess = publicEvents => {
  console.log('!!!fetchPublicEventsSuccess');

  return {
    type: actionTypes.FETCH_PUBLIC_EVENTS_SUCCESS,
    publicEvents,
  };
};

export const fetchShows = (eventType, movieGenre) => async (dispatch, getState) => {
  dispatch(fetchShowsStart());
  console.log('fetchShowsStart');
  try {
    API.get(
      'show/getCurrentShows?s1=fetch' +
      (eventType != null ? '&showtype=' + eventType : '') +
      (movieGenre != null ? '&moviegenre=' + movieGenre : '')
    )
      .then(function (response) {
        console.log('fetchShows process response');
        dispatch(fetchShowsSuccess(response.data.dataEntries));
        if (response.data.publicEvents !== null) {
          dispatch(fetchPublicEventsSuccess(response.data.publicEvents));
        }
      })
      .catch(function (error) {
        console.log('fetchShowsFail');
        dispatch(fetchShowsFail());
        dispatch(addError());
      })
      .finally(function () {
        console.log('fetchShowsEnd');
      });
  } catch (error) {
    dispatch(fetchShowsFail());
    dispatch(addError());
  }
};
